import { GetServerSideProps, NextPage } from "next";
import dynamic from "next/dynamic";
import { canonicalLink } from "@helpers/url";
import useResponsive from "@helpers/useResponsive";
import { getBackgroundLead } from "@libraries/api/background-lead/background-lead.repos";
import { getBanner } from "@libraries/api/banner/banner.repositories";
import { getPopularProduct } from "@libraries/api/popular-product/popular-product.repos";
import { getProductMenu } from "@libraries/api/product-menu/product-menu.repos";
import { getTestimony } from "@libraries/api/testimony/testimony.repos";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { localeKey } from "@constants/locale-key";
import { getArticle } from "@libraries/api/articles/articles.repos";

const Mobile = dynamic(() => import("@containers/LandingPage/libs/MobilePwa"));
const Desktop = dynamic(() => import("@containers/LandingPage/libs/Desktop/Desktop"));
const HeaderLP = dynamic(() => import("@layouts/Main/lib/Header/HeaderLP").then(mod => mod.HeaderLP));

const LandingPage: NextPage<any> = ({ datas }) => {
  const isMobile = useResponsive("sm", true);

  if (isMobile) {
    return <Mobile {...datas} page="home" />;
  }

  return (
    <>
      <HeaderLP />
      <Desktop {...datas} />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const lang = `${locale}`;
  const EN = lang === "en";

  const responseBanner = await getBanner(lang);
  const responsePopularProduct = await getPopularProduct(7, lang);
  const responseTestimony = await getTestimony(lang);
  const responseBackgroundLead = await getBackgroundLead();
  const responseInsightList = await getArticle(lang);

  const { data: dataProductMenu } = await getProductMenu({ level: 0 }, lang);
  const { data: dataBanner } = JSON.parse(JSON.stringify(responseBanner));
  const { data: dataPopularProduct } = JSON.parse(JSON.stringify(responsePopularProduct));
  const { data: dataTestimony } = JSON.parse(JSON.stringify(responseTestimony));
  const { data: dataBackgroundLead } = JSON.parse(JSON.stringify(responseBackgroundLead));
  const { data: dataArticle } = JSON.parse(JSON.stringify(responseInsightList));

  const datas = {
    banner: dataBanner,
    popularProduct: dataPopularProduct,
    testimony: dataTestimony,
    backgroundLead: dataBackgroundLead.image,
    dataNavbar: dataProductMenu,
    articleCustomer: dataArticle
  };

  return {
    props: {
      ...(await serverSideTranslations(lang, localeKey)),
      title: EN ? "Your Business Digital Transformation Partner" : "Partner Transformasi Digital Terbaik Bisnis Anda",
      description: EN
        ? "My Telkom Enterprise Solution offers a variety of digital solutions to help your business more efficiently and safely"
        : "My Telkom Enterprise Solution menawarkan berbagai solusi digital untuk membantu bisnis Anda dengan lebih efisien dan aman",
      imageUrl: "https://storeio.cloud.playcourt.id/ewz-mytens-pub-dev/mytensassets/favicon/icon-mytens-white-rectangle.png",
      canonical: canonicalLink("/", locale),
      datas,
    },
  };
};

export default LandingPage;
